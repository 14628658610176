import { createContext, useContext, useEffect, useRef, useState } from "react"
import { formatAMPM, setupPyodide } from "../utils";
import pythonParser  from '../parser' // lux

const pyContext = createContext();


export function PyContextProvider({ children }) {
    const [output, setOutput] = useState([])
    
    const py = useRef(null)
    
    const onCodeOutput = (s) => {
        if(s === "Python initialization complete"){return}
        setOutput((prev) => [{status:"successful", msg:s.toString(), timestamp: Date.now(), formatedTime:formatAMPM(new Date())}, ...prev])
    }
    
    const clearOutput = () => setOutput([])
        

    const onCodeError = (e) => {
        console.warn('error callback = ', e);
        setOutput((prev) => [{status:"error", msg:e.toString(), timestamp: Date.now(), formatedTime:formatAMPM(new Date())}, ...prev])
    }

    const setupPy = async () => {
        const xx = await setupPyodide(onCodeOutput, onCodeError)
        py.current = xx
    }

    const displayLevelCompleteMsg = (data) => {
        setOutput((prev) => [{status:"level_complete", msg:data.toString(), timestamp: Date.now(), formatedTime:formatAMPM(new Date())}, ...prev])
    }

    const sendMsg = (msgType, msg) => {
        console.warn(`output => ${msgType}, ${msg}`)
        setOutput((prev) => [{status:msgType, msg:msg?.toString(), timestamp: Date.now(), formatedTime:formatAMPM(new Date())}, ...prev])
    }

    useEffect(() => {
        if(py.current === null){
            setupPy()
        }
    }, [])

    const lux = (s) => {
        return pythonParser.parse(s)
    }

    const run = (s) => {
        if(py.current != null){
            try {
                return py.current.runPython(s)
            } catch (error) {
                console.log('run error ', error)
                setOutput((prev) => [{status:"error", msg:error.toString(), timestamp: Date.now(), formatedTime:formatAMPM(new Date())}, ...prev])
                return ""
            }
        }
        else{
            console.log('py is not ready', py.current)
            setupPy()
        }
    }

    return (
        <pyContext.Provider
          value={{
            output,
            clearOutput,
            run,
            lux,
            displayLevelCompleteMsg,
            sendMsg
          }}
        >
          {children}
        </pyContext.Provider>
      )
}

export function usePy() {
    return useContext(pyContext);
}