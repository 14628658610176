import React from 'react';
import { StoreProvider } from "easy-peasy";
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store from './models/main';
import Navbar from './components/Navbar';

const root = ReactDOM.createRoot(document.getElementById('root'))


root.render(
  <React.StrictMode>
      <StoreProvider store={store}>
            <App />
      </StoreProvider>
  </React.StrictMode>
);


reportWebVitals();
