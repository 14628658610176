import { usePy } from '../context/PyContext';
import { useRef, useState } from 'react';
import { useGameOne } from '../context/GameOneContext';
import LocalProfileIcon from '../icons/LocalProfileIcon';
import GameOneCodeBox from '../components/game1/GameOneCodeBox';

const GameOnePage = () => {
    const {run, output, lux, clearOutput } = usePy()
    const { onDebug, init, joinRoom, onRoomReady, room, roomState, level, submitAnswer} = useGameOne()
    const [inputValue, setInputValue] = useState(`print("hello")`)
    
   


    return(
        <div className="m-auto">
            
            <div className='text-center'>
                {room !== undefined && room.state?.players !== undefined  ?
                    <div>
                        <div className='flex justify-end text-md max-w-7xl m-auto'>
                            {roomState.players.map((item) => (
                                <div key={item.id}>
                                    <div className='flex flex-row-reverse space-x-reverse space-x-3 items-center p-3 rounded border-black border-2 w-fit'>
                                        <div className='w-fit'><LocalProfileIcon width="35" height="35"/></div>
                                        <div>{item.id}</div>
                                        <div>{item.score}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    :
                    <div>
                        --
                    </div>
                }
            </div>

            <div className='flex w-full mt-32 p-2 justify-around max-w-7xl m-auto' style={{minHeight:'900px'}}>
                {/* code box */}
                <div className='w-1/2 grow p-2 flex flex-col bg-color-one h-auto'>
                    <div className='h-full'>
                        <GameOneCodeBox
                            inputValue={inputValue}
                            output={output}
                            run={(s) => run(s)} 
                            setInputValue={(s) => setInputValue(s)}
                            submitAnswer={(s) => submitAnswer(s)}
                            clearOutput={() => clearOutput()}
                        />
                    </div>
                </div>
                
                <div className='w-9 flex justify-center items-center flex-row'>
                    <div className='w-1/2 h-3/4 m-auto rounded-lg flex justify-start flex-row items-end'>
                        <div className='hidden rounded-lg bg-green-500 h-full w-full' style={{height:'10%'}}>
                            
                        </div>
                    </div>
                </div> 

                {/* tut box */}
                <div className='w-1/2 grow p-2 flex flex-col bg-color-one pb-0' style={{maxHeight:'900px'}}>
                    <div className='w-full mb-0 border-b-2 justify-center border-black flex flex-row-reverse h-15'>
                        <div className='flex justify-around items-center w-full'>
                            <button className='px-4 bg-green-500 font-bold rounded' onClick={init}>RUN</button>
                            <button className='px-4 bg-green-500 font-bold rounded' onClick={onDebug}>DEBUG</button>
                            <button className='px-4 bg-green-500 font-bold rounded' onClick={() => joinRoom("chat")}>join</button>
                            <button className='px-4 bg-green-500 font-bold rounded' onClick={() => onRoomReady()}>ready</button>
                        </div>
                    </div>
                    <div className='w-full h-full flex flex-col grow relative overflow-y-auto '>
                        <div className='grow flex flex-col mb-3'>
                            {/* <textarea className='w-full  grow text-2xl p-2 bg-transparent mb-0' dir='rtl' type="text" value={levels[activeLevel].lvlDisc} disabled onChange={(e) => setInputValue(e.target.value)}/> */}
                            <div className='w-full  grow text-2xl p-2 bg-transparent mb-0' dir='rtl'>
                                {level?.quest !== undefined ? level.quest : ""}
                            </div>
                            <div className='bg-gray-400 p-2 my-4 mx-4 rounded'>
                                <div className='flex flex-row-reverse space-x-reverse space-x-3'>
                                    <input className='w-9 disabled:bg-white cursor-not-allowed' type={'checkbox'} disabled />
                                    <div>LEVEL_GOAL_01</div>
                                </div>
                            </div>
                        </div>
                        
                        <div className='sticky bottom-0 w-full mb-0'>
                            <div className='flex w-full items-center justify-center'>
                                <div className='flex flex-row-reverse items-center space-x-reverse space-x-2 bg-white px-8 py-2 rounded-tl-md rounded-tr-md font-bold'>
                                    <div>0%</div>
                                    <progress style={{height:'5px'}} dir='rtl' value={50}  max={100}/>
                                    <div role={"button"} className='rounded-full select-none border-2 border-black' style={{padding:'3px', aspectRatio:'9/3', textAlign:'center', fontSize:'13px'}}>؟</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default GameOnePage