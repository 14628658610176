import { request } from "../RequestManager"
import { getServerIp, httpHeader } from "../apiUtils"

const BASE_PATH = 'course-service'

const all = async (page=1, limit=8) => {
    const url = `${getServerIp()}api/cv/${BASE_PATH}/?${new URLSearchParams({ limit, page })}`
    const header = httpHeader("GET")
    try {
        const req    = await request(url, header)

        if(req.status === 200){
            return await req.json()
        }

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}

const get = async (id) => {
    const url = `${getServerIp()}api/cv/${BASE_PATH}/${id}`
    const header = httpHeader("GET")
    try {
        const req    = await request(url, header)

        if(req.status === 200){
            return await req.json()
        }

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}


const validate = async (answer, materialID) => {
    const url = `${getServerIp()}api/cv/${BASE_PATH}/validate/`
    const header = httpHeader("POST", {
        answer,
        materialID
    }, true)

    try {
        const req    = await request(url, header)

        if(req.status === 202){
            return await req.json()
        }

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}


const CourseService = {
    all,
    get,
    validate
}

export default CourseService