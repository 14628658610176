import { useRef } from "react"



const GameOneCodeBox = (props) => {
    const inputRef = useRef(undefined)


    const onInputKeyPressed = (e) => {
        if(e.keyCode === 9){
            e.preventDefault()
            // stackoverflow code #38385936
            const val = props.inputValue;
            const start = e.target.selectionStart;
            const end = e.target.selectionEnd;

            props.setInputValue(`${val.substring(0, start)}\t${val.substring(end)}`)
            setTimeout(() => {
                inputRef.current.selectionStart = inputRef.current.selectionEnd = start + 1
            }, 1)
        }
        if(e.keyCode === 13 & e.ctrlKey){
            props.run(props.inputValue)
        }
    }

    return(
        <div className="h-full flex flex-col">
            <div className='w-full h-15 flex justify-between items-center flex-row-reverse pr-2 pl-2 bg-transparent'>
                <div className='flex items-center p-1 flex-row-reverse space-x-reverse space-x-4'>
                    <div onClick={() => props.run(props.inputValue)} className='bg-green-700 px-3 py-1 text-white text-lg m-auto rounded-lg cursor-pointer select-none'>تشغيل</div>
                    <div onClick={() => props.setInputValue("")} onDoubleClick={props.clearOutput} className="select-none cursor-pointer text-lg">مسح</div>
                </div>

                <div className='flex items-center space-x-2'>
                    <button onClick={() => props.submitAnswer(props.inputValue)}>SEND</button>
                </div>
            </div>

            <textarea ref={inputRef} spellCheck="false" autoComplete='false' className='w-full h-full grow p-2 text-2xl bg-transparent border-black border-y-2' type="text" value={props.inputValue} onKeyDown={(e) => onInputKeyPressed(e)} onChange={(e) => props.setInputValue(e.target.value)}/>
            
            <div className='m-0 p-2 h-28 grow bg-transparent overflow-y-auto'>
                {props.output.map((outputLog, index) => (
                    <div key={index} className="text-2xl" >
                        {`=> ${outputLog}`}
                    </div>
                ))}
            </div>
        </div>
    )
} 


export default GameOneCodeBox