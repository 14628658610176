import { Link, useParams } from "react-router-dom"
import { useCodeController } from "../context/codeControllerContext"
import { usePy } from "../context/PyContext"
import CodeBox from "../components/CodeBox"
import TutorialBox from "../components/TutorialBox"
import { useCourseControler } from "../hooks/useCourseControler"
import AdminToolkit from "../components/AdminToolkit"
import { useState } from "react"




const CoursePage = () => {
    const { courseID } = useParams()
    const {output, clearOutput }             = usePy()
    const courseController = useCourseControler(courseID)
    const [counter, setCounter] = useState(0)

    return(
        <div className="h-screen grow">
            <AdminToolkit ishidden={counter < 5}/>
            <div className='flex w-full mt-10 p-2 justify-around max-w-7xl- m-auto  h-3/4- h-full ' >
                {/* tut box */}
                <div className='w-1/2 grow p-2 flex flex-col bg-color-one rounded-r-lg pb-0 h-2/3' style={{maxHeight:'800'}}>
                    <TutorialBox 
                        // courseActiveLevel={courseActiveLevel}
                        // isControllerLoading={isControllerLoading}
                        // isProgLoading={isProgLoading}
                        {...courseController}
                    />
                </div>
                
                <div className='w-1' onClick={() => setCounter((prev) => prev + 1)}/>

                {/* code box */}
                <div className='w-1/2 grow p-2 flex flex-col bg-color-one rounded-l-lg h-auto- h-2/3 bg-red-500-'>
                    <CodeBox
                        // inputValue={inputValue}
                        // setInputValue={(s) => setInputValue(s)}
                        // run={(s) => onRun(s)} 
                        // isControllerLoading={isControllerLoading}
                        // isProgLoading={isProgLoading}
                        clearOutput={clearOutput}
                        output={output}
                        {...courseController}
                    />
                </div>
            </div>
        </div>
    )
}

export default CoursePage