export const setupPyodide = async (outputCallback, errorCallback) => {
    const pyodide = await window.loadPyodide({
        indexURL : "https://cdn.jsdelivr.net/pyodide/v0.18.1/full/",
        stdout: (s) => outputCallback(s),
        stderr: (e) => errorCallback(e)
    });
    return pyodide
}

export const formatAMPM = (date, includePMAM=false) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours %= 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutes} ${includePMAM ? ampm : ""}`;
}
