import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import remarkBreaks from 'remark-breaks'

import { useCreateQuestion } from '../hooks/useCreateQuestion'


const CreateQuestionPage = (props) => {
    const createQuestionController = useCreateQuestion()
    const onDebug = () => console.log(createQuestionController)
    
   


    return(
        <div className="h-screen">
            <div className="w-full flex max-w-7xl m-auto p-2 h-16 items-center space-x-20 bg-gray-300">
                <button disabled={createQuestionController.isLoading} onClick={() => createQuestionController.onSubmit()} className={`w-36 h-10 rounded flex justify-center items-center text-white ${createQuestionController.selectedState === -1 ? "bg-green-500" : "bg-blue-500"} cursor-pointer`}>
                    {createQuestionController.selectedState === "-1" ? "حفظ" : "تعديل"}
                </button>

                <div/>
                
                <button disabled={createQuestionController.selectedState === "0"} onDoubleClick={() => createQuestionController.onRemoveQuestion()} className='text-red text-sm disabled:cursor-not-allowed'>حذف</button>
            
                <select className='px-10 text-center-' value={createQuestionController.selectedState} onChange={(e) => createQuestionController.onSelectedStateChange(e.target.value)}>
                    <option value={"-1"}>جديد</option>
                    {createQuestionController.levels.map((lvl, index) => (
                        <option dir='auto' className='text-center-' key={lvl.id} value={index}>
                            {`[#${lvl.id}] ${lvl.title}`}
                        </option>
                    ))}
                </select>

                <div className='cursor-pointer select-none' onClick={() => onDebug()}>debug</div>
                
                
                <select className='px-10 text-center' defaultValue={"validator"} value={createQuestionController.levelType} onChange={(e) => createQuestionController.setLevelType(e.target.value)}>
                    <option value={"validator"}>validator</option>
                    <option value={"reading"}>reading</option>
                </select>
            </div>
            
            {/* toolbar end */}
            
            <div className='h-full max-w-7xl m-auto'>
                <div className="flex max-w-7xl h-3/4 m-auto mt-8">
                    {/* edit box */}
                    <div className="w-1/2 bg-green-500">
                        <div className='flex flex-col bg-gray-300 h-full'>
                            <div className="text-xl text-center">Source</div>

                            <div className='flex justify-end- items-center mt-2 w-full'>
                                <div className='select-none'>الاسم</div>
                                <div className='flex w-full mr-2'>
                                    <input dir='auto' className='border p-2 space-x-4 w-full bg-white focus:outline-none' value={createQuestionController.title} onChange={(e) => createQuestionController.setTitle(e.target.value)} />
                                </div>
                            </div>

                            <textarea dir='rtl' spellCheck="false" autoComplete='false' className='w-full h-full grow p-2 text-2xl bg-transparent focus:outline-none' type="text" value={createQuestionController.body} onChange={(e) => createQuestionController.setBody(e.target.value)}/>       

                            <div className='border-t border-black'>
                                <textarea value={createQuestionController.example} onChange={(e) => createQuestionController.setExample(e.target.value)} dir='auto' className='bg-transparent w-full p-2 placeholder:text-right' placeholder='مثال' style={{height:'100px'}}/>
                            </div>

                            
                        </div>
                    </div>

                    <div className='w-9'/>

                    {/* view box */}
                    <div className="w-1/2">
                        <div className='flex flex-col bg-gray-300 h-full'>
                            <div className="text-xl text-center">View</div>
                            <div className='w-full mb-0 border-b-2 border-black flex flex-row h-15'>
                                <input dir='rtl' value={createQuestionController.title} disabled className='w-full p-2 text-xl font-bold grow bg-transparent flex' />
                                <div className='flex'>
                                    <div onClick={() => props.setPreviousLevel()} className='w-14 aspect-square select-none cursor-pointer flex items-center justify-center text-2xl m-0'>{`<`}</div>
                                    <div onClick={() => props.setNextLevel()} className='w-14 aspect-square select-none cursor-pointer flex items-center justify-center text-2xl m-0'>{`>`}</div>
                                </div>
                            </div>
                            <div dir='rtl' className='overflow-y-auto'>
                                <ReactMarkdown 
                                    // rehypePlugins={[rehypeHighlight, remarkGfm]} 
                                    rehypePlugins={[remarkBreaks, remarkGfm]} 
                                    className="text-2xl p-2" 
                                    children={createQuestionController.body}
                                    components={{
                                        em: ({node, ...props}) => <div className='text-center' {...props} />,
                                        code({node, inline, className, children, ...props}) {
                                            return(
                                                <span dir="ltr" className={`bg-[#f4f4f4] border border-[#ddd] text-[#666] break-inside-avoid break-words w-fit px-2 py-1 font-mono ${className}`} {...props}>
                                                    {children}
                                                </span>
                                            )
                                        }
                                        
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default CreateQuestionPage