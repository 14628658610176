import { request } from "../RequestManager"
import { getServerIp, httpHeader } from "../apiUtils"

const BASE_PATH = 'validator-admin-service'

const all = async (page=1, limit=8) => {
    const url = `${getServerIp()}api/cv/${BASE_PATH}/?${new URLSearchParams({ limit, page })}`
    const header = httpHeader("GET")
    try {
        const req    = await request(url, header)

        if(req.status === 200){
            return await req.json()
        }

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}

const get = async (id) => {
    const url = `${getServerIp()}api/cv/${BASE_PATH}/${id}`
    const header = httpHeader("GET")
    try {
        const req    = await request(url, header)

        if(req.status === 200){
            return await req.json()
        }

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}


const create = async (data) => {
    const url = `${getServerIp()}api/cv/${BASE_PATH}/`
    const header = httpHeader("POST", data, true)
    
    try {
        const req    = await request(url, header)
        const body   = await req.json()

        if(req.status === 201){
            return body
        }
        

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error)
    }
}


const patch = async (id, data) => {
    const url = `${getServerIp()}api/cv/${BASE_PATH}/${id}/`
    const header = httpHeader("PATCH", data, true)
    
    try {
        const req    = await request(url, header)
        const body   = await req.json()

        if(req.status === 200){
            return body
        }
        

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error)
    }
}


const destroy = async (id) => {
    const url = `${getServerIp()}api/cv/${BASE_PATH}/${id}/`
    const header = httpHeader("DELETE")
    
    try {
        const req    = await request(url, header)
        // const body   = await req.json()

        if(req.status === 204){
            return true
        }
        

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error)
    }
}

const validate = async (answer, validatorType, validatorBody, validatorScore) => {
    const url = `${getServerIp()}api/cv/${BASE_PATH}/validate/`
    const header = httpHeader("POST", {
        answer, 
        validatorType, 
        validatorBody, 
        validatorScore
    }, true)

    try {
        const req    = await request(url, header)

        if(req.status === 200){
            return await req.json()
        }

        throw Error(req.statusText)
    } 
    catch (error) {
        throw Error(error.message)
    }
}

const ValidatorsAdminService = {
    all,
    get,
    create,
    patch,
    destroy,
    validate
}

export default ValidatorsAdminService