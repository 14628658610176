import { createStore, action } from "easy-peasy";


const store = createStore({
    levels:[
        {
            "id":"1",
            "lvlName":"1.0 طباعة المتغيرات",
            "lvlDisc":"لطباعة قيمة باستخدام بايثون قم",
            "lvlExample":`user = "fahad"`,
            "isDone":false,
            "validator": (lux) => {
                let valid = false
                lux.forEach((x) => {
                    if(x.type === "PrintStatement"){
                        valid = true
                    }
                })
                return valid
            }
        },
        {
            "id":"2",
            "lvlName":"2.0 المتغيرات",
            "lvlDisc":"متغيرات تستطيع من خلالها حفظ قيمة للرجوع اليها لاحقا",
            "lvlExample":`user = "fahad"`
        }
    ],
    activeLevel:0,

    setActiveLevel: action((state, payload) => {
        state.activeLevel = payload
    }),

    setNextLevel: action((state) => {
        if(state.levels.length > 0 ){
            const nextIndex = ++state.activeLevel % state.levels.length 
            state.activeLevel = nextIndex
            console.log('next index= ', nextIndex);
        }
    }),

    setPreviousLevel: action((state) => {
        if(state.levels.length > 0){
            const len = state.levels.length;
            state.activeLevel = (state.activeLevel + len-1) % len 
        }
    }),
})

// {
//     "id":"1",
//     "lvlName":"1.0 طباعة المتغيرات",
//     "lvlDisc":"لطباعة قيمة باستخدام بايثون قم",
//     "lvlExample":`user = "fahad"`,
//     "isDone":false,
//     "validator": (lux) => {
//         let valid = false
//         lux.forEach((x) => {
//             if(x.type === "PrintStatement"){
//                 valid = true
//             }
//         })
//         return valid
//     }
// },
// {
//     "id":"2",
//     "lvlName":"2.0 المتغيرات",
//     "lvlDisc":"متغيرات تستطيع من خلالها حفظ قيمة للرجوع اليها لاحقا",
//     "lvlExample":`user = "fahad"`,
//     "isDone":false,
//     "validator": (lux) => {
//         let valid = false
//         lux.forEach((x) => {
//             if(x.type === "AssignmentExpressionStatement"){
//                 valid = true
//             }
//         })
//         return valid
//     }
// },
// {
//     "id":"3",
//     "lvlName":"3.0 لوب",
//     "lvlDisc":"متغيرات تستطيع من خلالها حفظ قيمة للرجوع اليها لاحقا",
//     "lvlExample":`user = "fahad"`,
//     "isDone":false,
//     "validator": (lux) => {
//         let valid = false
//         lux.forEach((x) => {
//             console.log(x.type);
//             if(x.type === "AssignmentExpressionStatement"){
//                 valid = true
//             }
//         })
//         return valid
//     }
// },
// {
//     "id":"4",
//     "lvlName":"4.0 تجربة",
//     "lvlDisc":"متغيرات تستطيع من خلالها حفظ قيمة للرجوع اليها لاحقا متغيرات تستطيع من خلالها حفظ قيمة للرجوع اليها لاحقا متغيرات تستطيع من خلالها حفظ قيمة للرجوع اليها لاحقا متغيرات تستطيع من خلالها حفظ قيمة للرجوع اليها لاحقا متغيرات تستطيع من خلالها حفظ قيمة للرجوع اليها لاحقا متغيرات تستطيع من خلالها حفظ قيمة للرجوع اليها لاحقا متغيرات تستطيع من خلالها حفظ قيمة للرجوع اليها لاحقا متغيرات تستطيع من خلالها حفظ قيمة للرجوع اليها لاحقا متغيرات تستطيع من خلالها حفظ قيمة للرجوع اليها لاحقا متغيرات تستطيع من خلالها حفظ قيمة للرجوع اليها لاحقا متغيرات تستطيع من خلالها حفظ قيمة للرجوع اليها لاحقا متغيرات تستطيع من خلالها حفظ قيمة للرجوع اليها لاحقا متغيرات تستطيع من خلالها حفظ قيمة للرجوع اليها لاحقا متغيرات تستطيع من خلالها حفظ قيمة للرجوع اليها لاحقا متغيرات تستطيع من خلالها حفظ قيمة للرجوع اليها لاحقا متغيرات تستطيع من خلالها حفظ قيمة للرجوع اليها لاحقا متغيرات تستطيع من خلالها حفظ قيمة للرجوع اليها لاحقا متغيرات تستطيع من خلالها حفظ قيمة للرجوع اليها لاحقا متغيرات تستطيع من خلالها حفظ قيمة للرجوع اليها لاحقا",
//     "lvlExample":`user = "fahad"`,
//     "isDone":false,
//     "validator": (lux) => {
//         let valid = false
//         lux.forEach((x) => {
//             console.log(x.type);
//             if(x.type === "AssignmentExpressionStatement"){
//                 valid = true
//             }
//         })
//         return valid
//     }
// },


// const levelsx = [
//     {
//         "id":"1",
//         "lvlName":"1.0 طباعة المتغيرات",
//         "lvlDisc":"لطباعة قيمة باستخدام بايثون قم",
//         "lvlExample":`user = "fahad"`,
//         "isDone":false,
//         "validator": (lux) => {
//             let valid = false
//             lux.forEach((x) => {
//                 if(x.type === "PrintStatement"){
//                     valid = true
//                 }
//             })
//             return valid
//         }
//     },
//     {
//         "id":"2",
//         "lvlName":"2.0 المتغيرات",
//         "lvlDisc":"متغيرات تستطيع من خلالها حفظ قيمة للرجوع اليها لاحقا",
//         "lvlExample":`user = "fahad"`
//     }
// ]

export default store