import { WarningOctagon } from "phosphor-react"
import { useNavigate } from "react-router-dom"


const LoadingComponent = ({isLoading, errorMsg}) => {
    const navigate = useNavigate()

    return(
        <div hidden={!errorMsg || isLoading}>
            <div className="my-10 m-auto max-w-4xl text-center">
                <WarningOctagon size={64} color="red" className="m-auto"/>
                <div className="my-6">
                    <p className="text-2xl">حدث خطأ</p>
                    <p className="text-xl">{errorMsg}</p>
                </div>

                <button onClick={() => navigate(0)} className="border py-1 px-8">اعادة المحاولة</button>
            </div>
        </div>
    )
}

export default LoadingComponent