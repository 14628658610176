import CodeBox from "../components/CodeBox"
import { useValidatorService } from "../hooks/useValidatorService"
import Editor from 'react-simple-code-editor'
import ReactJson from '@textea/json-viewer'


const CreateValidatorPage = () => {
    const validatorController = useValidatorService()
    const onDebug = () => console.log(validatorController)



    return(
        <div className="h-screen">
            <div className="w-full flex max-w-7xl m-auto p-2 h-16 items-center space-x-10 bg-gray-300">
                <button disabled={validatorController.isLoading} onClick={() => validatorController.onSubmit()} className={`w-36 h-10 rounded flex justify-center items-center text-white ${validatorController.selectedState === -1 ? "bg-green-500" : "bg-blue-500"} cursor-pointer`}>
                    {validatorController.selectedState === "-1" ? "حفظ" : "تعديل"}
                </button>

                <div/>
                
                <button disabled={validatorController.selectedState === "0"} onDoubleClick={() => validatorController.onRemoveValidator()} className='text-red text-sm disabled:cursor-not-allowed'>حذف</button>
            
                <select className='px-10 text-center-' value={validatorController.selectedState} onChange={(e) => validatorController.onSelectedStateChange(e.target.value)}>
                    <option value={"-1"}>جديد</option>
                    {validatorController.validators.map((validator, index) => (
                        <option dir='auto' className='text-center-' key={validator.id} value={index}>
                            {`[#${validator.id}] ${validator.title}`}
                        </option>
                    ))}
                </select>

                <div className='cursor-pointer select-none' onClick={() => onDebug()}>debug</div>
                
                
                <select className='px-10 text-center'  value={validatorController.validatorType} onChange={(e) => validatorController.setValidatorType(e.target.value)}>
                    <option value={"JS_BASIC_LX_AUX"}>JS_BASIC_LX_AUX</option>
                </select>

                <div className="flex items-center">
                    <div>الوصف</div>
                    <textarea dir="auto" value={validatorController.description} onChange={(e) => validatorController.setDescription(e.target.value)} className="resize-none focus:outline-none p-1"></textarea>
                </div>
            </div>


            <div className="h-3/4 max-w-7xl m-auto my-9">
                <div className='flex flex-row h-full'>

                    <div className='w-1/2 bg-gray-300 rounded'>
                        <div className="flex flex-col bg-gray-300- h-full">
                            <div className='h-1/2' style={{height:'50%'}}>

                                <div className='flex items-center py-2 rtl:justify-end px-2 h-12'>
                                    <div className='flex items-center grow px-2'>
                                        <div className='select-none'>الاسم</div>
                                        <input dir='auto' className='grow border p-1.5 mx-3 space-x-4 bg-white focus:outline-none' value={validatorController.title} onChange={(e) => validatorController.setTitle(e.target.value)} />
                                    </div>
                                    <div onClick={() => validatorController.onValidatorRun()} className="select-none cursor-pointer text">تشغيل</div>
                                </div>

                                {/* <textarea spellCheck="false" autoComplete='false' className='w-full h-full grow p-2 text-2xl bg-transparent focus:outline-none border-t-2 border-black hidden' type="text" value={validatorController.body} onChange={(e) => validatorController.onBodyChange(e.target.value)}/> */}

                                <Editor
                                    dir="ltr"
                                    className='focus:outline-none h-full border-t-2 border-black'
                                    value={validatorController.body}
                                    onValueChange={e => validatorController.onBodyChange(e)}
                                    // highlight={e => highlight(e, languages.plaintext)}
                                    highlight={e => e}
                                    tabSize={4}
                                    padding={10}
                                    textareaClassName="focus:outline-none"
                                    style={{
                                        fontFamily: '"Fira code", "Fira Mono", monospace',
                                        fontSize: 12
                                    }}
                                />
                            </div>
                            <div dir="ltr" className='h-1/2 '>
                                <ReactJson
                                    className='h-full p-2 pt-4 overflow-y-auto'
                                    name={false} 
                                    src={validatorController.luxOutput || {}}
                                    theme="ashes"
                                    enableClipboard={false}
                                    iconStyle='square'
                                    displayObjectSize={!false}
                                    displayDataTypes={false}
                                    onEdit={false}
                                    onAdd={false}
                                    onDelete={false} 
                                />
                            </div>
                        </div>
                    </div>


                    <div className='w-1'/>

                    <div className="w-1/2 bg-gray-300 rounded">
                        <CodeBox 
                            inputValue={validatorController.code}
                            setInputValue={validatorController.setCode}
                            onRun={validatorController.onRun}
                            isProgLoading={undefined} 
                            output={validatorController.output}
                            clearOutput={() => validatorController.clearOutput}
                        />
                    </div>


                </div>
            </div>

        </div>
    )
}


export default CreateValidatorPage