import { useRef } from "react"
import { usePy } from "../context/PyContext"
import { FormatOutputMessege } from "../utils"
import OutputMessageComponent from "./OutputMessageComponent"
import { FloppyDisk, Spinner } from "phosphor-react"



const CodeBox = (props) => {
    const inputRef = useRef(undefined)


    const onInputKeyPressed = (e) => {
        if(e.keyCode === 9){
            e.preventDefault()
            // stackoverflow code #38385936
            const val = props.inputValue;
            const start = e.target.selectionStart;
            const end = e.target.selectionEnd;

            props.setInputValue(`${val.substring(0, start)}\t${val.substring(end)}`)
            setTimeout(() => {
                inputRef.current.selectionStart = inputRef.current.selectionEnd = start + 1
            }, 1)
        }
        if(e.keyCode === 13 & e.ctrlKey){
            props.onRun(props.inputValue)
        }
    }

    return(
        <div className="h-full">
            <div className="flex flex-col h-full">
                <div className='w-full p-1 flex justify-between items-center flex-row pr-2 pl-2 bg-transparent'>
                    <div className='flex items-center space-x-2'>
                        {/* <button onClick={() => props.submitAnswer(props.inputValue)}>SEND</button> */}
                        <div className={`${props.isProgLoading === true ? "animate-spin block duration-100" : "hidden"}`}>
                            <Spinner size={32} color="black"/>
                        </div>
                    </div>

                    <div className='flex items-center p-1- space-x-reverse space-x-4 h-10'>
                        <div onClick={() => props.setInputValue("")} onDoubleClick={props.clearOutput} className="select-none cursor-pointer text-sm">مسح</div>
                        <div onClick={() => props.onRun(props.inputValue)} className='bg-green-700 px-6 py-1 text-sm text-white m-auto rounded-lg cursor-pointer select-none'>تشغيل</div>
                    </div>
                </div>

                <textarea dir="ltr" ref={inputRef} spellCheck="false" autoComplete='false' className='w-full h-full grow p-2 text-2xl bg-transparent border-black border-y-2 focus:outline-none' type="text" value={props.inputValue} onKeyDown={(e) => onInputKeyPressed(e)} onChange={(e) => props.setInputValue(e.target.value)}/>
                
                <div className='m-0 p-2 h-28 grow bg-transparent overflow-y-auto'>
                    {props?.output?.map((outputLog, index) => (
                        <div key={index} className="text-xl" >
                            <OutputMessageComponent {...outputLog} />   
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
} 


export default CodeBox