

const LocalProfileIcon = (props) => {
    return(
        // <svg xmlns="http://www.w3.org/2000/svg"  width="192" height="192" fill="#000000" viewBox="0 0 256 256">
        <svg xmlns="http://www.w3.org/2000/svg"  width={props.width ?? "192"} height={props.height ?? "192"} fill="#000000" viewBox="0 0 256 256">
            <rect width="256" height="256" fill="none"/>
            <circle cx="128" cy="96" r="64" fill="none" stroke="#000000" stroke-miterlimit="10" stroke-width="16"/>
            <path d="M31,216a112,112,0,0,1,194,0" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16"/>
        </svg>
    )
}

export default LocalProfileIcon